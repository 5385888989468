import React from "react";
import { PrivacyPolicyDe, PrivacyPolicyEn } from '@rentwareclients/privacy-policy'

import LayoutWidget from "../components/widgets/layout";
import Seo from "../components/seo";
import useTranslation from "../hooks/useTranslation";

const PrivacyPolicy = () => {
	const { language } = useTranslation();
  const hoster = "Netlify, Inc., located at 2325 3rd Street, Suite 296, San Francisco, California 94107"
  const contact = "Betterware Software UG, Abt.: Datenschutz, Ernst-Augustin-Straße 12, 12489 Berlin"
  const phone = "+49 30 920 385 4012"
  const emailLink = "datenschutz@rentware.com"
  const email = "datenschutz@rentware.com"
  return (
  <LayoutWidget>
    <Seo
      seo={{
        metaTitle: "Privacy policy",
        metaDescription: "It looks like you got to the privacy policy",
      }}
    />
    <main className="p-4 article">
      {language === "de" ?
        <PrivacyPolicyDe
            hoster={hoster}
            contact={contact}
            phone={phone}
            emailLink={emailLink}
            email={email}
            usercentrics={true}
            gtm={true}
            ga4={true}
            ga3={true}
            gaEventTracking={true}
        /> :
        <PrivacyPolicyEn
            hoster={hoster}
            contact={contact}
            phone={phone}
            emailLink={emailLink}
            email={email}
            usercentrics={true}
            gtm={true}
            ga4={true}
            ga3={true}
            gaEventTracking={true}
        />
      }
    </main>
  </LayoutWidget>
)};

export default PrivacyPolicy;
